import React from 'react'

import Contact from '../../Contact'
import Contacts from '../../Contacts'

import belov from '../images/belov.jpg'
import belov2x from '../images/belov@2x.jpg'
import ivushkin from '../images/ivushkin.jpg'
import ivushkin2x from '../images/ivushkin@2x.jpg'
import luzin from '../images/luzin.jpg'
import luzin2x from '../images/luzin@2x.jpg'
import matsapulina from '../images/matsapulina.jpg'
import matsapulina2x from '../images/matsapulina@2x.jpg'
import mozgov from '../images/mozgov.jpg'
import mozgov2x from '../images/mozgov@2x.jpg'
import naumov from '../images/naumov.jpg'
import naumov2x from '../images/naumov@2x.jpg'
import pankratova from '../images/pankratova.jpg'
import pankratova2x from '../images/pankratova@2x.jpg'
import sinitsyn from '../images/sinitsyn.jpg'
import sinitsyn2x from '../images/sinitsyn@2x.jpg'
import svetov from '../images/svetov.jpg'
import svetov2x from '../images/svetov@2x.jpg'
import toporov from '../images/toporov.jpg'
import toporov2x from '../images/toporov@2x.jpg'

export const HomeAllContacts = () => (
  <Contacts>
    <Contact
      email='mm@lp-russia.org'
      name='Марина Мацапулина'
      photo={matsapulina}
      photo2x={matsapulina2x}
      position='Председатель'
      telegram='spacehead' />
    <Contact
      email='mozgovty@lp-russia.org'
      name='Артём Мозгов'
      photo={mozgov}
      photo2x={mozgov2x}
      position='Зам. председателя'
      telegram='mozgovty' />
    <Contact
      email='belov@lp-russia.org'
      name='Георгий Белов'
      photo={belov}
      photo2x={belov2x}
      position='Федеральный комитет' />
    <Contact
      name='Иван Лузин'
      photo={luzin}
      photo2x={luzin2x}
      position='Этический комитет'
      telegram='RiardenSteel' />
    <Contact
      name='Тимур Наумов'
      photo={naumov}
      photo2x={naumov2x}
      position='Этический комитет' />
    <Contact
      email='svetov@lp-russia.org'
      name='Михаил Светов'
      photo={svetov}
      photo2x={svetov2x}
      position='Этический комитет'
      telegram='mr_libertarian' />
    <Contact
      name='Евгений Топоров'
      photo={toporov}
      photo2x={toporov2x}
      position='Этический комитет'
      telegram='evgenitop' />
    <Contact
      name='Алексей Ивушкин'
      photo={ivushkin}
      photo2x={ivushkin2x}
      position='Центральная контрольно-ревизионная комиссия' />
    <Contact
      name='Софья Панкратова'
      photo={pankratova}
      photo2x={pankratova2x}
      position='Центральная контрольно-ревизионная комиссия' />
    <Contact
      name='Иван Синицын'
      photo={sinitsyn}
      photo2x={sinitsyn2x}
      position='Центральная контрольно-ревизионная комиссия' />
  </Contacts>
)
